<router>
{
    name: 'Versions',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            DevopsVersions

</template>
<script>

import DevopsVersions from '@/components/devops/versions';

export default {
    meta: {
        role: 'devopsversions'
    },
    components: {
        DevopsVersions
    }
};
</script>

<style scoped>
</style>
