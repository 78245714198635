<template lang="pug">
.versionsList
    v-data-table(
        :headers='columns',
        :items='rows',
        single-expand=false,
        :expanded.sync='expanded',
        item-key='name',
        show-expand,
        dense,
        :search="search",
        :item-class="itemRowBackground",
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)",
        height='75vh',
        fixed-header,
        light,
        @pagination="paginator"
        disable-pagination,
        hide-default-footer
    )
        template(v-slot:top)
            v-toolbar(flat)
                v-row()
                    v-col()
                        v-toolbar-title {{ type }} Versions
                    v-spacer
                    v-col(cols='8' md='4' sm='6')
                        v-text-field(v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details color='brand' clearable, clear-icon='clear')
                    v-col(cols='1')
                        v-text-field(v-model="searchResults" readonly single-line hide-details color='brand' class="centered-input")
        template(v-slot:expanded-item="{headers, item}")
            td(:colspan="headers.length")
                div(v-if="item[`${prefix}Version`].error !== ''")
                    span Error: {{item[`${prefix}Version`].error}}
                v-card
                    v-card-title.brand.white--text.py-2 Firmware Versions
                    v-card-text
                        v-simple-table.pa-4(dense)
                            template(v-slot:default)
                                thead
                                    tr
                                        th Device
                                        th Firmware
                                        th Base
                                tbody
                                    tr(v-for='row in getTableData(item[`${prefix}Version`].firmware)')
                                        td(v-for='value in row') {{ value }}

        template(v-slot:item.domainUrl="{header, item}")
            v-text-field(
                hide-details='auto',
                single-line,
                dense,
                v-model='item[header.value]',
                @keyup.enter='saveData(item.id, { [header.value]: item[header.value] })',
                color='brand'
            )
        template(v-slot:item.name="{item}")
            v-btn(small, icon, @click.stop='getVersionData(item.id)')
                v-icon refresh
            span {{ item.name }}

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import _ from 'lodash';
import dti from 'dti';

export default {
    data () {
        return {
            dti,
            message: '',
            search: '',
            expanded: [],
            searchResults: 0
        };
    },
    props: ['columns', 'rows', 'type', 'editColumns', 'urls', 'prefix'],
    methods: {
        async saveData (id, data) {
            this.axios
                .post(`${this.urls.base}${this.urls.save}`, { id, data })
                .then((response) => {
                    this.message = `Save: ${response.data.msg}`;
                });
        },
        toggleRow (row) {
            this.expanded.push(row);
        },
        itemRowBackground (item) {
            return (item[`${this.prefix}Version`].error !== '') ? 'errorRow' : '';
        },
        convertFwProp (prop) {
            switch(prop) {
            case 'fw':
                return 'Firmware';
            case 'base':
                return 'Base';
            default:
                return prop;
            }
        },
        getTableData (firmWareData) {
            const rows = [];
            if (firmWareData) { // Only public sites will have firmware data.
                const deviceTypes = Object.keys(firmWareData);
                for (const deviceType of deviceTypes) {
                    const deviceFirmwareData = firmWareData[deviceType];
                    const firmwareVersion = deviceFirmwareData['fw'] || 'N/A';
                    const baseVersion = deviceFirmwareData['base'] || 'N/A';
                    const row = [deviceType, firmwareVersion, baseVersion];
                    rows.push(row);
                }
            }
            return rows;
        },
        getVersionData (serverId) {
            if (this.$props.type === 'Customers') {
                this.$emit('getCustomerVersion', serverId);
            } else {
                this.$emit('getServerVersion', serverId);
            }
        },
        paginator (pagination) {
            this.searchResults = pagination.itemsLength;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        }
    },
    mounted () {
        window.customerversionvm = this;
    },
};
</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
</style>

<style lang="scss">
.versionsList {
    .versionTable {
        td {
            position: relative;
        }
    }
    width: 100%;
}

.errorRow {
    background-color: #ef5350;
}

.centered-input input {
    text-align: center;
}
</style>
