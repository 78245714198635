<template lang="pug">
.versionPage
    .versionButtons
        v-row.ma-2
            v-btn.ma-2(
                :color="index === currentPage ? 'accentForeground' : 'accent'",
                v-for="(page, index) in pages",
                @click="changePage(index)",
                :key="page.title",
                small
            ) {{ page.title }}
            v-spacer
            v-btn.ma-2(
                color="brand",
                @click="getAllVersions",
                :disabled="gettingData",
                small
            ) Request Server Data
            v-btn.ma-2(color='brand', @click='runSecurityScan', :disabled='gettingData', small) Run Security Scan
    .versionTable
        VersionTable(
            :columns="pages[currentPage].columns",
            :rows="pages[currentPage].rows",
            :type="pages[currentPage].title",
            :urls="pages[currentPage].urls",
            :prefix="pages[currentPage].prefix",
            @getCustomerVersion='getCustomerVersion',
            @getServerVersion='getServerVersion'
        )
        span Please Wait...
    v-dialog(v-model='securityTest.visible', :persistent='securityTest.running', max-width='50vw')
        v-card(light)
            v-card-title Security Checks
            v-card-text
                template(v-if='securityTest.running')
                    div.text-center
                        .text-h5.text-center Running security scan... This might take a while.
                        v-progress-circular(:size='50', color='brand', indeterminate)
                template(v-else)
                    v-simple-table.striped(dense)
                        template(v-slot:default)
                            thead
                                tr
                                    th Customer
                                    th Mongo Connection
                            tbody
                                tr(v-for='customer in securityTest.results', :key='customer.name')
                                    td
                                        span {{ customer.name }}
                                    td
                                        template(v-if='customer.mongoConnection')
                                            v-icon(color='yellow') warning
                                                span This server is at risk!
                                        template(v-else)
                                            span No Connection
            v-card-actions
                v-spacer
                v-btn(text, @click='securityTest.visible = false', :disabled='securityTest.running')

    v-overlay(absolute, :value='gettingData')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

</template>

<script>
import _ from "lodash";
import dti from "dti";
import VersionTable from "./versionTable.vue";

export default {
    components: {
        VersionTable,
    },
    data () {
        return {
            dti,
            accessKey: "devopsversions",
            message: "",
            customers: [],
            servers: [],
            currentPage: 0,
            gettingData: false,
            securityTest: {
                visible: false,
                running: false,
                results: []
            },
            pages: [
                {
                    columns: [
                        { text: "Customer", value: "name", editable: false },
                        {
                            text: "Last Updated",
                            value: "customersVersion.lastUpdateFromServer",
                            editable: false,
                        },
                        {
                            text: "Site Name",
                            value: "customersVersion.siteName",
                            editable: false,
                        },
                        {
                            text: "Domain",
                            value: "customersConfig.domainUrl",
                            editable: true,
                        },
                        {
                            text: "Code",
                            value: "customersVersion.jsCodeServer",
                            editable: false,
                        },
                        {
                            text: "Electron",
                            value: "customersVersion.electron",
                            editable: false,
                        },
                        {
                            text: "Processes",
                            value: "customersVersion.processes",
                            editable: false,
                        },
                        {
                            text: "Update Src",
                            value: "customersVersion.updateSource",
                            editable: false,
                        },
                        { text: "", value: "data-table-expand" },
                    ],
                    rows: [],
                    title: "Customers",
                    urls: {get: "getCustomers"},
                    prefix: 'customers'
                },
                {
                    columns: [
                        { text: "Server", value: "name", editable: false, align: "start" },
                        {
                            text: "Last Updated",
                            value: "serversVersion.lastUpdateFromServer",
                            editable: false,
                            fx: this.formattedDate,
                        },
                        {
                            text: "Site Name",
                            value: "serversVersion.siteName",
                            editable: false,
                        },
                        { text: "Domain", value: "serversConfig.domainUrl", editable: true },
                        {
                            text: "Code",
                            value: "serversVersion.jsCodeServer",
                            editable: false,
                        },
                        {
                            text: "Electron",
                            value: "serversVersion.electron",
                            editable: false,
                        },
                        {
                            text: "Processes",
                            value: "serversVersion.processes",
                            editable: false,
                        },
                        {
                            text: "Set Port",
                            value: "serversConfig.setPort",
                            editable: false,
                        },
                        {
                            text: "Active Port",
                            value: "serversConfig.activePort",
                            editable: false,
                        },
                        { text: "", value: "data-table-expand" },
                    ],
                    rows: [],
                    title: "Internal Servers",
                    urls: {get: "getInternalServers"},
                    prefix: 'servers'
                },
            ],
        };
    },
    methods: {
        changePage (data) {
            this.currentPage = data;
        },
        formattedDate (dateString) {
            if (dateString) {
                let date = new Date(dateString);

                return date.toLocaleString("en-US");
            }
        },
        async getData () {
            for (let page of this.pages) {
                this.socketEmit(page.urls.get, ["versions", "config"], (results) => {
                    for (const version of results) {
                        if (version.customersVersion) {
                            version.customersVersion.lastUpdateFromServer = this.getPrettyDate(version.customersVersion.lastUpdateFromServer);
                        } else {
                            version.serversVersion.lastUpdateFromServer = this.getPrettyDate(version.serversVersion.lastUpdateFromServer);
                        }
                    }
                    page.rows = results;
                    page.rows.sort((a, b) => {
                        return a.name > b.name;
                    });
                    for (let row of page.rows) {
                        if (row[`${page.prefix}Version`].firmware) {
                            row[`${page.prefix}Version`].firmware = JSON.parse(row[`${page.prefix}Version`].firmware);
                        }
                    }
                });
            }
        },
        async getAllVersions (customerId) {
            this.gettingData = true;
            await this.sendCommand({
                action: 'getAllVersions',
                parameters: customerId
            });
            this.gettingData = false;
        },
        async refreshData () {
            this.gettingData = true;

            await this.getAllVersions();

            await this.getData();
            this.gettingData = false;
        },
        async getCustomerVersion (customerIds) {
            this.gettingData = true;
            if (customerIds && !Array.isArray(customerIds)) {
                customerIds = [customerIds];
            }

            await this.sendCommand({
                action: 'getCustomerVersion',
                parameters: customerIds
            });
            await this.getData();
            this.gettingData = false;
        },
        async getServerVersion (serverIds) {
            this.gettingData = true;
            if (serverIds && !Array.isArray(serverIds)) {
                serverIds = [serverIds];
            }

            await this.sendCommand({
                action: 'getServerVersion',
                parameters: serverIds
            });
            await this.getData();
            this.gettingData = false;
        },
        async runSecurityScan () {
            this.gettingData = true;
            this.securityTest.visible = true;
            this.securityTest.running = true;
            const result = await this.sendCommand({
                action: 'runSecurityScan'
            });
            this.securityTest.running = false;
            this.securityTest.results = result;
            this.gettingData = false;
        },
        getPrettyDate (date) {
            return new Date(date).toLocaleString();
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== "";
            },
            set (val) {
                if (val === false) {
                    this.message = "";
                }
            },
        },
    },
    watch: {},
    mounted () {
        this.getData();
        window.customerupdatevm = this;
    },
};
</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
</style>

<style lang="scss">
.versionsList {
    .versionTable {
        td {
            position: relative;
        }
    }
}
</style>
